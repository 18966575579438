.members-details {
  .admin-content-wrapper-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-group {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &-buttons {
      button {
        width: auto;
        background-color: #1a73e9;
        color: #ffffff;
        border: solid 1px #1a73e9;
        padding: 6px 40px;
        font-size: 16px;
        font-weight: normal;
        margin-right: 20px;
        &:hover {
          background-color: #ffffff;
          color: #1a73e9;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-image {
      width: 100px;
      max-width: 100px;
      min-width: 100px;
      height: 100px;
      border-radius: 8px;
      border: solid 1px #dadce0;
      position: sticky;
      position: -webkit-sticky;
      top: 95px;
      padding: 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &-infos {
      width: calc(100% - 130px);
      padding: 0;
      border-radius: 8px;
      border: solid 1px #dadce0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      &-item {
        width: calc(50% - 40px);
        display: flex;
        flex-direction: row;
        border-top: solid 1px #dadce0;
        padding: 20px;
        align-items: flex-start;
        &:nth-child(2n+1) {
          width: calc(50% - 41px);
          //padding-right: 20px;
          border-right: solid 1px #dadce0;
        }
        &:nth-child(2n) {
          //padding-left: 20px;
        }
        &:nth-child(1), &:nth-child(2) {
          border-top: none;
        }
        &.full {
          width: 100%;
        }
        li {
          line-height: 20px;
          &:first-child {
            min-width: max-content;
            margin-right: 5px;
            color: #6f6f6f;
          }
          a {
            color: #1a73e9;
            text-decoration: underline;
          }
        }
        /*&:nth-child(2n+1) {
          border-right: solid 1px #dadce0;
        }*/
      }
    }
  }
}