.icons-wrapper {
  h2 {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .icons {
    margin-bottom: 50px;
    .page-header {
      &-add {
        min-width: 215px !important;
        a {
          padding: 6px 25px;
        }
      }
    }
    .icons-data-grid {
      display: block;
      width: 100%;
      > div {
        height: 100% !important;
      }
    }
  }
}
