.create-admin-content {
  width: 100%;
  //height: calc(100vh - 78px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .page-header {
    &-add {
      min-width: 211px !important;
      a {
        padding: 6px 25px;
      }
    }
  }
  .admin-list {
    width: 100%;
    .MuiDataGrid-dataContainer {
      .MuiDataGrid-cell {
        &:nth-child(8) {
          text-overflow: initial;
          line-height: 20px !important;
          max-height: unset !important;
          white-space: unset !important;
          padding: 5px;
        }
      }
    }
  }
}