.adverts-content {
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .grid-wrapper{
      min-height: 60vh;
      display: flex;
  }
  .MuiTabs-root {
    .MuiTabs-flexContainer {
      .MuiButtonBase-root {
        width: 33.33%;
        max-width: 100%;
        text-transform: none;
        font-size: 16px;
        min-width: max-content;
        @media only screen and (max-width: 768px) {
          width: auto;
          font-size: 14px;
        }
        &.Mui-selected {
          color: #1a73e9;
          background-color: #e8f0fe;
        }
      }
    }
  }
  .MuiBox-root {
    padding: 20px 0;
    .content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .dashboard-ad-view {
        width: calc(50% - 15px);
        @media only screen and (max-width: 1200px) {
          width: calc(50% - 5px);
        }
        .dashboard-ad-view-link {
          box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.25);
        }
        .dashboard-ad-view-image {
          width: 180px;
        }
        .dashboard-ad-view-link-text {
          width: calc(100% - 180px);
          padding: 20px;
        }
        @media screen and (max-width: 992px) {
          width: 100%;
          .dashboard-ad-view-link-image {
            width: 180px;
          }
          .dashboard-ad-view-link-text {
            width: calc(100% - 220px);
            padding: 20px;
          }
        }
        @media screen and (max-width: 768px) {
          .dashboard-ad-view-link-image {
            width: 100%;
          }
          .add-item-link-text {
            width: calc(100% - 40px);
          }
        }
        @media screen and (max-width: 480px) {
          .dashboard-ad-view-link-text {
            width: calc(100% - 20px);
            padding: 10px;
          }
        }
      }
    }
  }
}