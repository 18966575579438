.admin-login-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  &-form {
    display: flex;
    flex-direction: column;
    width: 22rem;
    padding: 3rem 5rem;
    border: 1px solid #dadce0;
    background: #fff;
    border-radius: 8px;
    @media only screen and (max-width: 992px) {
      width: calc(100vh - 40px);
      margin-top: -58px;
      padding: 0 20px;
    }
    @media only screen and (max-width: 768px) {
      border: none;
    }

    .logo-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 20px;
          &-logo {
            span.logo-line {
            font-size: 3rem;
            text-transform: lowercase;
            font-family: 'airstream-webfont', sans-serif;
            transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            color: #f56b2b;
          }
          span.logo-second-line {
            color: #6f6f6f;
            font-size: 1.2rem;
            margin-left: 3px;
            font-weight: normal;
            font-family: 'Roboto', sans-serif;
          }
      }
    }

    .full-form-wrapper {
      .errorMessage {
        bottom: -7px;
      }
    }

    .forgot-password {
      margin: 15px 0;
      a {
        margin-left: 0;
      }
    }

    &-field {
      &.MuiFormControl-root {
        .MuiInputLabel-outlined {
          transform: translate(14px, 20px) scale(1);
          &.MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.75);
          }
        }
      }
      .MuiInputBase-input {
        height: 36px;
      }
    }

    &-title {
      font-size: 1.5rem;
      text-align: center;
      margin-bottom: 1rem;
      font-family: 'Roboto', Avenir, sans-serif;
    }

    &-subtitle {
      text-align: center;
      font-size: 1.125rem;
      margin-bottom: 1rem;
      font-family: 'Roboto', Avenir, sans-serif;
    }

    a {
      color: #0073f1;
      margin: 1rem 0 1rem .5rem;

      &:hover {
        text-decoration: underline;
      }
    }

    .MuiButton-label {
      color: #0073f1;
      text-transform: initial;
      font-family: 'Avenir', sans-serif;
      font-size: 1rem;
    }

    .MuiFormLabel-root.Mui-focused {
      color: #0073f1!important;
    }

    .MuiTextField-root {
      width: 100%;
      margin: 1rem 0;

      .MuiFormLabel-root.Mui-focused {
        color: #0073f1!important;
      }

      .MuiOutlinedInput-root.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: #0073f1!important;
        }
      }

      &.is-invalid {
        .MuiFormLabel-root.Mui-focused {
          color: #ff4444 !important;
        }

        .MuiOutlinedInput-root.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: #ff4444!important;
          }
        }
      }
    }

    &-line {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .half-form-wrapper:nth-child(2) {
        display: flex;
        justify-content: flex-end;
      }
      button {
        width: 100%;
        background: #0073f1!important;
        box-shadow: none;
        padding: 12px;
        font-weight: normal;
        &:disabled {
          background-color: #1a73e9;
        }
        .MuiCircularProgress-root {
          width: 20px !important;
          height: 20px !important;
          color: #ffffff !important;
          margin-left: 10px;
        }
        span {
          color: #fff!important;
        }
      }
    }
  }
}
