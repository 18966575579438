.admin-header-content {
  background: #fff;
  color: #000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 3rem;
  z-index: 1301;
  transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  box-shadow: 0 .1rem .5rem rgba(#000, .25);
  @media screen and (max-width: 1150px) {
    padding: 15px 25px;
  }

  @media screen and (max-width: 992px) {
    padding: 5px 10px;
  }

  .bigger {
    font-size: 16px;
    @media screen and (max-width: 1150px) {
      font-size: 15px;
    }
    @media screen and (max-width: 992px) {
      font-size: 16px;
    }
  }

  &-logo {
    font-size: 3rem;
    text-transform: lowercase;
    font-family: 'airstream-webfont', sans-serif;
    transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    color: #f56b2b;
    span {
      color: #6f6f6f;
      font-size: 1.3rem;
      margin-left: 5px;
      font-weight: normal;
      font-family: 'Roboto', sans-serif;
    }
  }

  .menu-container {
    ul {
      padding: 0 0 0 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      li {
        padding: 0 20px;
        border-right: solid 1px #dadce0;
        color: #3c4043;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          border-right: none;
          padding-right: 0;
        }
        &.admin-link-icon {
          a {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #3c4043;
            &:hover, &.active {
              color: #f56b2b;
            }
            /*svg {

            }
            span {

            }*/
          }
        }
        &.user-profile {
          @media screen and (max-width: 992px) {
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            width: 100%;
          }
          button {
            text-transform: none;
            font-size: 16px;
            color: #3c4043;
            @media screen and (max-width: 992px) {
              padding: 6px 0;
              margin-left: 20px;
            }
            .MuiButton-label {
              display: flex;
              flex-direction: row;
              align-items: center;
              .user-icon {
                width: 20px;
                font-size: 20px;
                margin-right: 5px;
                position: relative;
                top: -2px;
              }
              .user-caret {
                width: 30px;
                font-size: 23px;
                margin-left: 0;
              }
            }
          }
          .user-popper {
            top: 20px !important;
            outline: none;
            @media screen and (max-width: 992px) {
              position: relative !important;
              top: 0 !important;
              left: 0 !important;
              transform: none !important;
              width: 100%;
            }
            .MuiPaper-root {
              min-width: 330px;
              margin-top: 0;
              border-radius: 8px;
              outline: none;
              box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
              @media screen and (max-width: 992px) {
                box-shadow: none;
                border-top: none;
                border-bottom: none;
              }
              .MuiList-root {
                width: 100%;
                padding: 0;
                .user-profile-inner {
                  width: 100%;
                  padding: 15px 0 0 0;
                  @media only screen and (max-width: 992px) {
                    padding-top: 0;
                  }
                  &-top {
                    display: flex;
                    flex-direction: column;
                    margin: 10px 0 10px 0;
                    align-items: center;
                    justify-content: center;
                    @media only screen and (max-width: 992px) {
                      align-items: flex-start;
                      padding: 0;
                      margin: 0;
                    }
                    &-image {
                      width: 70px;
                      height: 70px;
                      border-radius: 50%;
                      border: solid 1px #ececec;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: center;
                      box-shadow: 0 4px 10px 2px rgba(150, 150, 150, 0.3);
                      @media screen and (max-width: 992px) {
                        display: none;
                      }
                      svg {
                        font-size: 4.5rem;
                        color: #1a73e9;
                      }
                      img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-position: center;
                        object-fit: cover;
                      }
                    }
                    &-username {
                      margin: 10px 0 6px 0;
                      @media screen and (max-width: 992px) {
                        display: none;
                      }
                      h3 {
                        font-size: 20px;
                        font-weight: normal;
                      }
                    }
                    &-email {
                      margin-bottom: 15px;
                      @media screen and (max-width: 992px) {
                        display: none;
                      }
                      ul {
                        display: flex;
                        flex-direction: row;
                        font-weight: normal;
                        li {
                          margin-left: 0;
                          border: none;
                          padding: 0;
                          font-size: 14px;
                          color: #6f6f6f;
                          &:nth-child(2) {
                            margin: 0 5px;
                          }
                        }
                      }
                    }
                    a {
                      border:  solid 1px #cacaca;
                      padding: 10px 20px;
                      border-radius: 20px;
                      margin-bottom: 5px;
                      text-transform: none;
                      font-size: 15px;
                      height: auto;
                      color: #000000;
                      @media screen and (max-width: 992px) {
                        padding: 20px 10px;
                        border: none;
                        margin-bottom: 0;
                        margin-left: 35px;
                      }
                      &:hover {
                        background-color: #f2f2f2;
                        color: #1a73e9;
                        &:after {
                          display: none;
                        }
                      }
                    }
                  }
                  &-bottom {
                    padding: 10px 0;
                    border-top: solid 1px #dbdce0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    @media only screen and (max-width: 992px) {
                      align-items: flex-start;
                      padding: 0;
                      margin: 0;
                      border-top: none;
                    }
                    button {
                      width: calc(100% - 20px);
                      margin-left: 0;
                      height: auto;
                      padding: 8px 10px;
                      text-align: center;
                      border-radius: 8px;
                      border: solid 1px #dadce0;
                      color: #000000;
                      justify-content: center;
                      transition: .3s ease-in-out;
                      @media only screen and (max-width: 992px) {
                        width: auto;
                        padding: 20px 10px;
                        margin-left: 35px;
                      }
                      &:hover {
                        background-color: #f2f2f2;
                        color: #1a73e9;
                        &:after {
                          display: none;
                        }
                      }
                    }
                    @media only screen and (max-width: 992px) {
                      .mobile-hide {
                        display: none;
                      }
                    }
                  }
                  &-footer {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    padding: 10px;
                    border-top: solid 1px #dbdce0;
                    @media only screen and (max-width: 992px) {
                      justify-content: flex-start;
                      padding: 0;
                      border-top: none;
                    }
                    button {
                      width: 100%;
                      border: solid 1px #dadce0;
                      padding: 5px 20px;
                      text-transform: none;
                      transition: .3s ease-in-out;
                      border-radius: 8px;
                      @media only screen and (max-width: 992px) {
                        padding: 20px 10px 10px 10px;
                        border: none;
                        margin-left: 35px;
                        .MuiButton-label {
                          line-height: 16px;
                        }
                      }
                      &:hover {
                        background-color: #f2f2f2;
                        color: #1a73e9;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.countryMenu {
          .dropdown {
            width: auto;
            background-color: transparent;
            position: relative;
            &.open {
              transition: .2s ease-in-out;
              border-radius: .2rem .2rem 0 0;
              box-shadow: 0 0.1rem 0.2rem rgba(0,0,0,.25);
              .dropdown-header {
                svg {
                  transition: .2s ease-in-out;
                  position: absolute;
                  top: 50%;
                  right: 10px;
                  transform: rotate(180deg) translateY(50%);
                }
              }
              .dropdown-body {
                border-radius: 0 0 .2rem .2rem;
                -webkit-transition: visibility, opacity .3s ease-in-out;
                -moz-transition: visibility, opacity .3s ease-in-out;
                transition: visibility, opacity .3s ease-in-out;
                visibility: visible;
                opacity: 1;
                pointer-events: all;
                box-shadow: 0 0.1rem 0.2rem rgba(0,0,0,.25);
              }
            }
            &-header {
              min-width: 120px;
              padding: 10px 30px 10px 10px;
              cursor: pointer;
              display: flex;
              position: relative;
              justify-content: space-between;
              align-items: center;
              div {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                img {
                  width: 22px;
                  margin-right: 10px;
                }
                span {
                  font-size: 14px;
                }
              }
              svg {
                transition: .2s ease-in-out;
                position: absolute;
                top: 50%;
                right: 8px;
                transform: translateY(-50%);
              }
            }
            &-body {
              -webkit-transition: visibility, opacity .3s ease-in-out;
              -moz-transition: visibility, opacity .3s ease-in-out;
              transition: visibility, opacity .3s ease-in-out;
              min-width: 120px;
              width: calc(100% - 10px);
              background-color: #ffffff;
              padding: 5px;
              visibility: hidden;
              opacity: 0;
              max-height: 400px;
              overflow: auto;
              pointer-events: none;
              /*border-top: 1px solid #E5E8EC;*/
              position: absolute;
              top: 34px;
              left: 0;
              display: block;
              ul {
                width: calc(100% - 10px);
                padding: 5px;
                margin: 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                li {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-bottom: 5px;
                  font-size: 14px;
                  padding: 0;
                  border: none;
                  cursor: pointer;
                  img {
                    width: 22px;
                    margin-right: 10px;
                  }
                  span {
                    font-size: 14px;
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}