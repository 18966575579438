.advert-details {
  .item-details {
    width: calc(100% - 55px);
    padding: 15px 40px 15px 15px;
    .admin-content-wrapper-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0;
      &-group {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .item-details-menu {
      top: 17px;
      right: 10px;
    }
  }
  &-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    button {
      width: auto;
      min-width: 200px;
      background-color: #1a73e9;
      color: #ffffff;
      border: solid 1px #1a73e9;
      padding: 6px 40px;
      font-size: 16px;
      font-weight: normal;
      margin-right: 20px;
      &:hover {
        background-color: #ffffff;
        color: #1a73e9;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    &-title {
      width: 100%;
      margin-bottom: 20px;
      padding-left: 0;
      h2 {
        font-size: 20px;
      }
    }
    &-image {
      width: 120px;
      max-width: 120px;
      min-width: 120px;
      height: 120px;
      border-radius: 8px;
      border: solid 1px #dadce0;
      position: sticky;
      position: -webkit-sticky;
      top: 95px;
      padding: 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &-infos {
      width: calc(100% - 150px);
      padding: 0;
      display: flex;
      flex-direction: column;
      &-description {
        width: calc(100% - 40px);
        border-radius: 8px 8px 0 0;
        border: solid 1px #dadce0;
        border-bottom: none;
        padding: 20px;
        ul {
          display: flex;
          flex-direction: row;
          li {
            line-height: 20px;
            &:first-child {
              min-width: max-content;
              margin-right: 5px;
              color: #6f6f6f;
            }
          }
        }
      }
      &-others {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-radius: 0 0 8px 8px;
        border: solid 1px #dadce0;
        &.system {
          margin-top: 40px;
          border-radius: 8px;
        }
        .block-wrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          border-top: solid 1px #dadce0;
        }
        &-item {
          width: calc(50% - 40px);
          display: flex;
          flex-direction: row;
          border-top: solid 1px #dadce0;
          padding: 20px;
          align-items: flex-start;
          &:nth-child(2n+1) {
            width: calc(50% - 41px);
            //padding-right: 20px;
            border-right: solid 1px #dadce0;
          }
          &:nth-child(2n) {
            //padding-left: 20px;
          }
          &:nth-child(1), &:nth-child(2) {
            border-top: none;
          }
          &.full {
            width: 100%;
          }
          li {
            line-height: 20px;
            &:first-child {
              min-width: max-content;
              margin-right: 5px;
              color: #6f6f6f;
            }
            a {
              color: #1a73e9;
              text-decoration: underline;
              &:hover {
                text-decoration: underline !important;
              }
            }
          }
          /*&:nth-child(2n+1) {
            border-right: solid 1px #dadce0;
          }*/
        }
      }
    }
  }
}