.members-list {
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .page-header {
    display: flex;

    flex: 1;
  }

  .grid-wrapper {
    display: flex;
    min-height: 60vh;
    flex: 1;
  }
}