.brands {
  h1 {
    width: 100%;
    font-size: 28px;
    line-height: 32px;
    margin: 0 0 20px 0;
  }
  .page-header {
    &-add {
      min-width: 230px !important;
      a {
        padding: 6px 25px;
      }
    }
  }
}