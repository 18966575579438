.categories {
  .page-header {
    &-add {
      min-width: 214px !important;
      button {
        padding: 6px 25px;
      }
    }
  }
}

.category-icon {
  width: 22px;
  margin-right: 10px;
}