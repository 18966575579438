body {
  overflow: unset !important;
}

.MuiButton-root {
  text-transform: none !important;
}

.page {
  &-content {
    width: 100%;
    display: flex;
    margin: 70px 0;
    padding: 50px 0;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 400px;
    @media screen and (max-width: 992px) {
      margin: 58px 0;
    }
  }
  &-container {
    width: 100%;
    max-width: 65rem;
    margin: 0 auto;
    padding: 0;
  }
}

.MuiLink-underlineHover:hover {
  text-decoration: none !important;
}

#actions-menu {
  .MuiPaper-root {
    .MuiList-root {
      li {
        svg {
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background: #fff;
  padding: 0 5px 0 0;
}

.MuiFormControl-root.is-invalid .MuiTextField-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #0073f1 !important
}

.MuiFormControl-root.is-invalid label {
  color: #f44336;
}

/* Custom arrow button */
.custom-arrow-button {
  width: 100%;
  height: 42px !important;
  display: flex !important;
  justify-content: space-between !important;
  border-color: rgba(0, 0, 0, 0.23) !important;
  color: rgba(0, 0, 0, 0.54) !important;
  text-transform: none !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  padding: 10px 5px 10px 10px !important;
  box-shadow: none !important;
  &:hover {
    background-color: #ffffff !important;
    border-color: rgba(0, 0, 0, 0.87) !important;
  }
}

/* Form general style */
.half-form-wrapper {
  width: 50%;
  position: relative;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  .MuiFormControl-root {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.half-form-wrapper:nth-child(2n+1) {
  padding: 0 10px 0 0;
  @media screen and (max-width: 480px) {
    padding: 0;
  }
}

.half-form-wrapper:nth-child(2n) {
  padding: 0 0 0 10px;
  @media screen and (max-width: 480px) {
    padding: 0;
  }
  .errorMessage {
    left: 10px;
    @media screen and (max-width: 480px) {
      left: 0;
    }
  }
}

.full-form-wrapper {
  width: 100%;
  position: relative;
  &.validated {
    margin-top: 20px;
  }
  .MuiFormControl-root {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.tel-input {
  .errorMessage {
    left: 50px;
  }
}

/* Error message */
.errorMessage {
  position: absolute;
  left: 0;
  bottom: -12px;
  color: #f44336;
  margin: 0;
  font-size: 0.8rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  padding: 0;
}

.is-invalid .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

.MuiFormControl-root.is-invalid .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

/* Show and hide password input */
.show-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555555;
  font-size: 1.5rem;
  margin-left: .5rem;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    transform: rotate(-45deg);
    width: 2px;
    height: 100%;
    background: #555555;
    border-right: 2px solid #fff;
    transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  &.active {
    &::after {
      height: 0;
    }
  }
}

/* Dialog container */

.MuiDialog-root .MuiDialog-container {
  height: calc(100% - 78px);
  position: relative;
  top: 78px;
}

.large {
  .MuiDialog-paperWidthSm {
    min-width: 600px;
    @media only screen and (max-width: 600px) {
      min-width: 100%;
    }
  }
}

.dialog {
  .closeButton {
    position: absolute;
    top: -60px;
    right: -18px;
    color: #ffffff;
    font-size: 30px;
  }
  .MuiPaper-rounded {
    background-color: transparent;
    overflow: visible;
    box-shadow: none;
    .change-container {
      &.custom-modal {
        width: 600px;
        .MuiDialogContent-root {
          padding: 30px 25px;
        }
        .dialog-container {
          form {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .full-form-wrapper {
              margin-bottom: 30px;
              &:last-child {
                margin-bottom: 0;
              }
              .errorMessage {
                font-size: 14px;
                bottom: -24px;
              }
              &.tel-input {
                .react-tel-input {
                  margin: 0;
                  input {
                    width: 100%;
                  }
                  .special-label {
                    display: none;
                  }
                  .form-control {
                    padding: .55rem 1rem;
                    margin-left: 3rem;
                    min-height: 56px;
                    width: calc(100% - 3rem);

                    & ~ .flag-dropdown {
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      padding: 0;
                      border-radius: 3px 0 0 3px;

                      &::before {
                        left: 3.5rem;
                        color: #6b6f74;
                        transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
                        content: attr(data-placeholder)!important;
                        position: absolute;
                        top: -7px;
                        display: block;
                        background: #fff;
                        padding: 0 5px;
                        font-size: 13px;
                        white-space: nowrap;
                      }

                      .selected-flag {
                        outline: none;
                        position: relative;
                        width: 52px;
                        height: 100%;
                        padding: 0;
                        border-radius: 3px 0 0 3px;

                        .flag {
                          position: absolute;
                          top: 50%;
                          margin-top: -12px;
                          .arrow {
                            position: relative;
                            top: 50%;
                            margin-top: -1px;
                            left: 29px;
                            width: 0;
                            height: 0;
                            border-left: 3px solid transparent;
                            border-right: 3px solid transparent;
                            border-top: 4px solid #555;
                          }
                        }
                      }
                    }

                    &:focus {
                      & ~ .flag-dropdown {
                        &::before {
                          color: #0073f1;
                        }
                      }
                    }
                  }
                  &.is-invalid {
                    .form-control {
                      &:focus {
                        box-shadow: 0 0 0 1px #f4f4f4;
                        & ~ .flag-dropdown {
                          &::before {
                            color: #ff4444;
                          }
                        }
                      }
                    }
                  }
                }
                .is-invalid {
                  .form-control {
                    border: solid 1px #ff4444;
                    color: #ff4444;
                  }
                }
              }
              &.checkbox-group {
                margin: -20px 0 0 -11px;
                .MuiFormLabel-root {
                  margin: 0 0 30px 0;
                  padding: 0;
                  color: #000000;
                }
                .MuiFormGroup-root.MuiFormGroup-row {
                  .MuiFormControlLabel-root {
                    margin-left: 0;
                    margin-right: 11px;
                  }
                }
              }
            }
            .half-form-wrapper {
              width: calc(50% - 10px);
              margin-bottom: 30px;
              &:last-child {
                margin-bottom: 0;
              }
              .errorMessage {
                font-size: 14px;
                bottom: -24px;
              }
              &.tel-input {
                .react-tel-input {
                  margin: 0;
                  input {
                    width: 100%;
                  }
                  .special-label {
                    display: none;
                  }
                  .form-control {
                    padding: .55rem 1rem;
                    margin-left: 3rem;
                    width: calc(100% - 5.2rem);

                    & ~ .flag-dropdown {
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      padding: 0;
                      border-radius: 3px 0 0 3px;

                      &::before {
                        left: 3.5rem;
                        color: #6b6f74;
                        transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
                        content: attr(data-placeholder)!important;
                        position: absolute;
                        top: -7px;
                        display: block;
                        background: #fff;
                        padding: 0 5px;
                        font-size: 13px;
                        white-space: nowrap;
                      }

                      .selected-flag {
                        outline: none;
                        position: relative;
                        width: 52px;
                        height: 100%;
                        padding: 0;
                        border-radius: 3px 0 0 3px;

                        .flag {
                          position: absolute;
                          top: 50%;
                          margin-top: -12px;
                          .arrow {
                            position: relative;
                            top: 50%;
                            margin-top: -1px;
                            left: 29px;
                            width: 0;
                            height: 0;
                            border-left: 3px solid transparent;
                            border-right: 3px solid transparent;
                            border-top: 4px solid #555;
                          }
                        }
                      }
                    }

                    &:focus {
                      & ~ .flag-dropdown {
                        &::before {
                          color: #0073f1;
                        }
                      }
                    }
                  }
                  &.is-invalid {
                    .form-control {
                      &:focus {
                        box-shadow: 0 0 0 1px #f4f4f4;
                        & ~ .flag-dropdown {
                          &::before {
                            color: #ff4444;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .picture {
              width: calc(100% - 80px);
              border-radius: 8px;
              background: #fff;
              margin-bottom: 25px;
              padding: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              @media screen and (max-width: 768px) {
                width: 100%;
                border: none;
                padding: 0;
              }
              &-choose {
                width: 107px;
                min-width: 107px;
                height: 107px;
                min-height: 107px;
                border-radius: 8px;
                border: solid 2px #f56b2b;
                display: flex;
                flex-direction: column;
                padding: 10px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                transition: .3s ease-in-out;
                @media screen and (max-width: 768px) {
                  width: 95px;
                  height: 95px;
                  min-width: 95px;
                }
                @media screen and (max-width: 768px) {
                  width: 80px;
                  height: 80px;
                  min-width: 80px;
                }
                input {
                  display: none;
                }
                label {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  svg {
                    color: #f56b2b;
                    font-size: 2.5rem;
                    margin-bottom: 15px;
                    @media only screen and (max-width: 768px) {
                      font-size: 2rem;
                    }
                  }
                  span {
                    text-align: center;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 15px;
                    color: #f56b2b;
                    @media only screen and (max-width: 768px) {
                      font-size: 14px;
                    }
                  }
                }
              }
              &-default {
                width: 125px;
                min-width: 125px;
                height: 125px;
                min-height: 125px;
                border-radius: 8px;
                background-color: #ffffff;
                //border: solid 1px #dadce0;
                display: flex;
                flex-direction: column;
                padding: 0;
                position: absolute;
                top: 3px;
                left: 3px;
                pointer-events: none;
                overflow: hidden;
                transition: .3s ease-in-out;
                @media screen and (max-width: 768px) {
                  border: none;
                  width: 115px;
                  height: 115px;
                  min-width: 115px;
                }
                @media screen and (max-width: 480px) {
                  width: 100px;
                  height: 100px;
                  min-width: 100px;
                }
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
                button {
                  width: 30px;
                  height: 30px;
                  position: absolute;
                  top: -1px;
                  right: -1px;
                  background-color: #ff4444;
                  color: #ffffff;
                  border: none;
                  outline: none;
                  cursor: pointer;
                  border-radius: 0 0 0 15px;
                  svg {
                    font-size: 20px;
                  }
                }
              }
            }
            .validation {
              display: flex;
              justify-content: flex-end;
              margin-top: 10px;
              .add-button {
                background-color: #1a73e9;
                color: #ffffff;
                padding: 12px 40px;
              }
            }
          }
        }
      }
      padding: 0;
      width: 360px;
      max-height: 435px;
      //overflow: auto;
      background-color: #ffffff;
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.375);
      border-radius: .1rem;
      display: flex;
      flex-direction: column;
      &.delete-modal {
        .MuiDialogContent-root {
          overflow: visible;
        }
      }
      @media only screen and (max-width: 768px) {
        width: 90vw;
        height: auto;
        max-height: 74vh;
        margin: auto;
      }
      .MuiDialogTitle-root {
        height: 25px;
        padding: 15px 20px !important;
        @media only screen and (max-width: 480px) {
          height: auto;
          padding: 12px 15px !important;
        }
        .MuiTypography-root {
          font-size: 18px;
          @media only screen and (max-width: 480px) {
            font-size: 16px;
          }
        }
      }
      .MuiDialogContent-root {
        padding: 20px 15px;
        height: calc(100% - 116px);
        border-top: 1px solid #eaebed;
        border-bottom: 1px solid #eaebed;
        overflow-y: auto;
        .dialog-container {
          p {
            text-align: center;
            font-size: 16px;
            line-height: 20px;
          }
          .react-tel-input {
            margin: 0;
            input {
              width: 100%;
            }
            .special-label {
              display: none;
            }
            .form-control {
              padding: .55rem 1rem;
              margin-left: 3rem;
              width: calc(100% - 3rem);

              & ~ .flag-dropdown {
                &::before {
                  left: 3.5rem;
                  color: #6b6f74;
                  transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
                  content: attr(data-placeholder)!important;
                  position: absolute;
                  top: -7px;
                  display: block;
                  background: #fff;
                  padding: 0 5px;
                  font-size: 13px;
                  white-space: nowrap;
                }

                .selected-flag {
                  padding: 0;
                }
              }

              &:focus {
                & ~ .flag-dropdown {
                  &::before {
                    color: #0073f1;
                  }
                }
              }
            }
            &.is-invalid {
              .form-control {
                &:focus {
                  box-shadow: 0 0 0 1px #f4f4f4;
                  & ~ .flag-dropdown {
                    &::before {
                      color: #ff4444;
                    }
                  }
                }
              }
            }
          }
          &-item {
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
            .MuiTextField-root {
              width: 100%;
              margin: 0;

              .MuiOutlinedInput-input {
                padding: 14px 32px 10px 10px !important;
              }
            }
            .MuiFormControl-root {
              width: 100%;
            }
          }
        }
      }
      .confirmation-button {
        padding: 12px 8px 8px 8px;
        height: 34px;
        width: calc(100% - 16px);
        button {
          width: 100%;
          display: flex;
          justify-content: center;
          background-color: #1a73e9;
          color: #ffffff;
          text-transform: none;
          margin-bottom: 5px;
          &:disabled {
            background-color: #1a73e9;
          }
          .MuiCircularProgress-root {
            width: 20px !important;
            height: 20px !important;
            color: #ffffff !important;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.MuiDialog-container {
  @media screen and (max-width: 992px) {
    height: calc(100% - 58px);
    position: relative;
    top: 58px;
  }
}

/* Tabs style */
.MuiTabs-scroller {
  border-bottom: solid 1px #dbdce0 !important;
}
.MuiTabs-indicator {
  height: 4px !important;
  background-color: #1a73e9 !important;
}

/* Countries flag */
.react-tel-input .flag-dropdown .country-list .search {
  box-shadow: 0 0.1rem 0.15rem rgba(100, 100, 100, 0.25) !important;
}
.react-tel-input .flag-dropdown .country-list .search-box {
  width: calc(100% - 10px) !important;
}

/* Load More Section */

.load-more-content {
  width: 100%;
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 14px;
    margin-bottom: 15px;
    /*color: #989898;*/
    color: #333333;
  }
  button {
    width: auto;
    display: inline-flex;
    margin: 0;
    background-color: #1a73e9;
    color: #ffffff;
    padding: 8px 70px;
    text-transform: none;
    font-size: 18px;
    transition: .3s ease-in-out;
    border: solid 1px #1a73e9;
    font-weight: normal;
    &:disabled {
      background-color: #1a73e9;
      color: #ffffff;
    }
    &:hover {
      background-color: #ffffff;
      color: #1a73e9;
    }
    .MuiButton-label {
      .MuiCircularProgress-root {
        width: 24px !important;
        height: 24px !important;
        margin-left: 15px;
        color: #ffffff;
      }
    }
  }
}

/* Default modal style */
@media screen and (max-width: 992px) {
  .dialog .MuiPaper-rounded .change-container {
    width: auto;
  }
}

#menu-categories .MuiPaper-root.MuiMenu-paper .MuiList-root.MuiMenu-list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: .3s ease-in-out;
  &:hover {
    color: #f56b2b;
    img {
      margin-right: 10px;
      filter: invert(47%) sepia(97%) saturate(418%) hue-rotate(333deg) brightness(96%) contrast(86%);
    }
  }
}

#menu-categories .MuiPaper-root.MuiMenu-paper .MuiList-root.MuiMenu-list li img {
  margin-right: 10px;
  width: 20px;
  transition: .3s ease-in-out;
}

/* Fixed buttons */
.mobile-fixed-buttons {
  width: 100%;
  display: none;
  flex-direction: row;
  background-color: #f56b2b;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  margin: 0;
  padding: 0;
  min-height: 58px;
  @media screen and (max-width: 768px) {
    display: flex;
  }
  &-item {
    width: 33.33%;
    padding: 0;
    button {
      width: 100%;
      padding: 7px 10px 5px 10px;
      color: #ffffff;
      font-weight: normal;
      border-radius: 0;
      .MuiButton-label {
        display: flex;
        flex-direction: column;
        svg {
          font-size: 22px;
          margin-bottom: 0;
        }
        span {
          text-transform: none;
          font-size: 14px;
        }
      }
    }
  }
}

/* Admin common style */
.admin-content-wrapper {
  &-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    a {
      margin-right: 12px;
      border: solid 1px #dadce0;
      &.MuiIconButton-root {
        padding: 6px;
      }
    }
    h2 {
      font-size: 1.4rem;
    }
  }
}

.item-details {
  width: calc(100% - 60px);
  border: solid 1px #dadce0;
  border-radius: 8px;
  padding: 20px;
  /*padding: 20px 40px 20px 20px;*/
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  margin: 0 0 20px 0;
  position: relative;
  &-menu {
    position: absolute;
    top: 10px;
    right: 5px;
    button {
      padding: 6px;
    }
  }
  &-infos {
    width: 100%;
    display: flex;
    flex-direction: row;
    ul {
      width: 33.33%;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      li {
        &:first-child {
          margin-right: 10px;
          color: #6f6f6f;
        }
        &:last-child {
          color: #000000;
        }
      }
    }
  }
}

.page-header {
  width: calc(100% - 40px);
  border: solid 1px #dadce0;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 20px 0;
  button {
    width: auto;
    background-color: #1a73e9;
    color: #ffffff;
    border: solid 1px #1a73e9;
    padding: 6px 40px;
    font-size: 16px;
    font-weight: normal;
    &:hover {
      background-color: #ffffff;
      color: #1a73e9;
    }
  }
  a {
    display: block;
    width: auto;
    text-align: center;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    background-color: #1a73e9;
    color: #ffffff;
    border: solid 1px #1a73e9;
    padding: 6px 40px;
    font-size: 16px;
    font-weight: normal;
    &:hover {
      background-color: #ffffff;
      color: #1a73e9;
    }
  }
  &-add {
    width: 170px;
    margin-right: 20px;
  }
  &-search {
    width: 100%;
    .MuiFormControl-root {
      width: calc(100% - 170px);
      margin-right: 20px;
      .MuiInputBase-root {
        &.hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: #dadce0;
          }
        }
      }
      .MuiSvgIcon-root {
        color: #6f6f6f;
      }
      .MuiOutlinedInput-input {
        padding: 12px 20px 12px 10px;
      }
      .MuiOutlinedInput-notchedOutline {
        border-radius: 20px;
        border-color: #dadce0;
      }
      &.MuiFormControl-root {
        .MuiInputLabel-outlined {
          transform: translate(14px, 14px) scale(1);
          &.MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.75);
          }
        }
      }
    }
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: #dadce0;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #1a73e9;
    }
    .MuiOutlinedInput-root.Mui-focused svg {
      color: #1a73e9;
    }
    button {
      width: 150px;
    }
  }
}

.MuiFormControl-root {
  .MuiFormLabel-root {
    background-color: #ffffff;
    z-index: 1;
    transform: translate(10px, 20px) scale(1);
    padding: 0 4px;
    &.MuiInputLabel-shrink {
      transform: translate(10px, -6px) scale(0.75);
    }
  }
}

/* Data Grid */

.MuiDataGrid-viewport {
  .rendering-zone {
    .MuiButtonBase-root {
      border: solid 1px #dadce0;
    }
  }
}

.MuiDataGrid-row .MuiDataGrid-cell div {
  display: flex;
  align-items: center;
}

.MuiDataGrid-root{
  border-radius: 8px !important;
}

.MuiDataGrid-root .MuiDataGrid-footer.MuiDataGrid-footer-justifyContentEnd {
  border-top: 1px solid rgba(224, 224, 224, 1);
}

.admin-dashboard-content {
  .grid-wrapper {
    > div {
      height: auto !important;
    }
  }
}

/* Notice infos */
.notice-content {
  width: 100%;
  margin: 0 0 20px 0;
  p {
    color: #1d77d0;
    font-size: 14px;
    margin: 0;
    display: flex;
    align-items: center;
    svg {
      font-size: 18px;
      margin-right: 4px;
    }
  }
}

/* Admin Custom page styles */
.admin-add-content {
  width: calc(100% - 40px);
  padding: 30px;
  max-width: 768px;
  margin: 0  auto;
  border: solid 1px #dadce0;
  border-radius: 8px;
  @media only screen and (max-width: 768px) {
    padding: 30px 20px;
    border: none;
  }
  @media only screen and (max-width: 480px) {
    width: calc(100% - 30px);
    padding: 25px 15px;
  }

  h1 {
    font-size: 30px;
    margin: 0 0 30px 0;
  }

  form {
    .full-form-wrapper {
      margin: 0 0 35px 0;
      &:last-child {
        margin-bottom: 0;
      }
      .errorMessage {
        bottom: -26px;
      }
    }
    /*.models {
      margin-bottom: 10px;
    }*/
    .validation {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      .add-button {
        background-color: #1a73e9;
        border: solid 1px #1d77d0;
        color: #ffffff;
        padding: 12px 40px;
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        &:hover {
          background-color: #ffffff;
          color: #1d77d0;
          .MuiCircularProgress-root {
            svg {
              color: #1d77d0;
            }
          }
        }
        &:disabled {
          background-color: #1d77d0;
          &:hover {
            background-color: #1d77d0;
          }
        }
        .MuiCircularProgress-root {
          width: 24px !important;
          height: 24px !important;
          margin-left: 12px;
          svg {
            color: #ffffff;
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
          }
        }
      }
    }
  }
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #f56b2b !important;
}

/* Menu */
.MuiPopover-root {
  .MuiPaper-root {
    ul.MuiList-root {
      a {
        color: #000000;
        background-color: #ffffff;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        padding: 8px 16px;
        width: 100%;
        display: flex;
        position: relative;
        box-sizing: border-box;
        text-align: left;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        outline: none;
        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
        svg {
          margin-right: 8px;
          font-size: 20px;
        }
      }
      li {
        svg {
          font-size: 20px;
          margin-right: 8px;
        }
      }
    }
  }
}

/* Data loading  */

.data-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .MuiCircularProgress-root {
    width: 24px !important;
    height: 24px !important;
    margin-left: 15px;
    color: #1a73e9;
    margin-bottom: 8px;
  }
}

/* Select style */

.MuiSelect-root.MuiSelect-select {
  display: flex;
  align-items: center;
}