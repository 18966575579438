.cities {
  .page-header {
    &-add {
      min-width: 204px !important;
      a {
        padding: 6px 25px;
      }
    }
  }
}
