.discussions-details {
  display: flex;
  flex-direction: column;
  .admin-content-wrapper-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-group {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    button {
      width: auto;
      background-color: #1a73e9;
      color: #ffffff;
      border: solid 1px #1a73e9;
      padding: 6px 40px;
      font-size: 16px;
      font-weight: normal;
      &:hover {
        background-color: #ffffff;
        color: #1a73e9;
      }
    }
  }
  &-infos {
    border: solid 1px #dadce0;
    border-radius: 8px;
    padding: 20px 15px 0 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    ul {
      width: calc(50% - 10px);
      padding: 0;
      margin: 0 0 20px 0;
      display: flex;
      flex-direction: row;
      &:nth-child(2n){
        padding-right: 10px;
      }
      &:nth-child(2n+1) {
        padding-left: 10px;
      }
      li {
        &:first-child {
          margin-right: 5px;
        }
      }
    }
  }
  .message-list {
    /*.MuiDataGrid-root .MuiDataGrid-cell {
      max-height: unset;
      white-space: nowrap;
      overflow: unset;
    }*/
  }
}